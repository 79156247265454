<template>
  <div>
    <the-app-bar />
    <div class="font-montserrat top">
      <div class="row py-24 px-12 text-center oonpay-bg">
        <div class="col-md-12">
          <h6 class="text-warning">Your balance</h6>
          <h2 class="text-white">GHC 0.00</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <button
            type="button"
            class="btn bg-warning"
            data-toggle="modal"
            data-target="#transferFundsModal"
          >
            Send Funds
          </button>
        </div>
        <div class="col-md-6 d-flex justify-content-start">
          <button type="button" class="btn btn-outline-warning"  data-toggle="modal"
            data-target="#loadWalletModal">Load Wallet</button>
        </div>
      </div>

      <!--Send Funds Modal -->
      <div
        class="modal fade"
        id="transferFundsModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="transferFundsModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="transferFundsModalLabel">Transfer Funds</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <small>Your Balance </small>
              <h4>GHS 0.00</h4>
              <h5 class="my-3">Beneficiary Deatils</h5>
              <div class="form-group">
                <select name="country" class="form-control" id="">
                    <option value="">Select Country</option>
                </select>
              </div>
              <div class="form-group">
                <label for="Phone">Phone Number</label>
                <input type="text" placeholder="enter phone number" class="form-control">
              </div>

              <div class="form-group">
                <label for="Amount">Amount</label>
                <input type="text" placeholder="0" class="form-control">
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Transfer Funds
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Load Wallect -->
      <div
        class="modal fade"
        id="loadWalletModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loadWalletModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="loadWalletModalLabel">Transfer Funds</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <small>Your Balance </small>
              <h4>GHS 0.00</h4>
              

              <div class="form-group">
                <label for="Amount">Amount</label>
                <input type="text" placeholder="0" class="form-control">
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Top Up
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid  mt-5 p-5">
        <div class="row">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-md-8">
                <h2>Transaction History</h2>

                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Address</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transaction ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                      <td>12.000078</td>
                      <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                    </tr>
                    <tr>
                      <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                      <td>15.000000</td>
                      <td>@2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
  color: aliceblue;
}

.bg-warning {
  background: #e87c34 !important;
  color: aliceblue;
  font-weight: 500;
}
</style>
